.block {
  padding: $base-gutter * 2;
  margin-bottom: 16px;

  &--without-padding {
    padding: 0;
  }

  &--rounded {
    border-radius: $base-border-radius * 2;
  }

  &--white {
    background: $color-white;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__col {
    width: calc(50% - 10px);
    flex-shrink: 0;
  }

  &__field {
    margin-bottom: 16px;
    position: relative;
  }

  &__footer {
    margin-top: 30px;
  }

  &__actions {
    display: flex;
    gap: 16px;
  }
}

.disabled-block {
  position: relative;

  // Потому что after используется реже, чем before
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: $color-white-op-75;
    border-radius: inherit;
    cursor: not-allowed;
  }
}
