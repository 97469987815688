.sidebar-inner-enter-active,
.sidebar-inner-leave-active {
  transition: transform .2s;
}
.sidebar-inner-enter,
.sidebar-inner-leave-to {
  transform: translateX(-100%);
}

.v-enter-active,
.v-leave-active {
  transition: opacity $base-animation;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
