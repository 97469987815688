.login {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 16px 0;

  @include for-size(phone-portrait-down) {
    background-color: $color-white;
    padding: 16px 8px;
  }

  &__form {
    position: relative;
    width: 700px;
    background-color: $color-white;
    box-shadow: $base-shadow-xl;
    padding: 80px 107px;
    border-radius: $base-border-radius * 1.5;
    display: flex;
    flex-direction: column;

    @include for-size(phone-portrait-down) {
      width: 100%;
      padding: 0;
      box-shadow: none;
    }
  }

  &__title {
    margin-bottom: 20px;
    @include for-size(phone-portrait-down) {
      margin-bottom: 16px;
    }
  }

  &__caption {
    font-size: 14px;
    line-height: 20px;
    color: $color-grayscale-60;
    margin-bottom: 16px;
    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }

  &__input {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }
}
