@use "sass:math";

.tile-grid {
  $grid-gap: 24px;

  display: flex;
  flex-wrap: wrap;
  margin: -(math.div($grid-gap, 2));

  // tile item
  & > * {
    margin: math.div($grid-gap, 2);
    width: 260px;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    row-gap: 24px;
    column-gap: 20px;
    grid-auto-flow: row;

    // unset
    flex-wrap: unset;
    margin: unset;

    & > * {
      margin: unset;
      width: unset;
    }
  }
}
