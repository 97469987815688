
// EsmpUI Components settings
// $esmp-collapser-border-radius: 50px; // так должно быть, так как в библиотеке эта переменная с настройкой !default

//$esmp-collapser-border-radius: $base-border-radius * 2;
.esmp-collapser {
  border-radius: $base-border-radius * 2;
}

// TODO: esmp-upload
.esmp-upload {
  &--as-drag {
    .esmp-upload__zone--select {
      width: 100%;
      display: unset;
    }
  }
}

:root {
  --esmp-ui-table-tr-hovered-background: unset;
  --esmp-ui-table-tr-selected-background: unset;
}
