
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        
// TODO: разобраться с импортом стилей из библиотеки.
// Нужно импортить именно scss, чтобы можно было перебивать !default переменные и не тянуть лишние стили

@import "~@esmpfrontend/ui/dist/ui.css";
@import "variables";
@import "typography";
@import "transitions";
@import "mixins/misc";
@import "indents";

@import "esmp-ui-settings";

@import "layout/page";
@import "layout/grid";
@import "layout/block";
@import "layout/accent-block";

@import "blocks/tickets-list";
@import "blocks/login";
@import "blocks/control-settings";
@import "blocks/ckeditor";
@import "blocks/business-trip";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-print-color-adjust: exact;
  -webkit-tap-highlight-color: transparent;
  outline: 0;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

body {
  font-family: $base-font-family;
}

textarea {
  font-family: inherit;
}

.esmp-tooltip {
  z-index: 2;
}

img {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.broken-image {
    border: 1px solid $border-color-base;
  }
}
