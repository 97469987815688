@import '~@esmpfrontend/ui/src/assets/scss/typography.scss';
@import "variables.scss";

// TODO: убрать в @esmpfrontend строгое задание цвета, должно быть inherit
// TODO: добавить в @esmpfrontend Caption – 12pt

.typo-caption-s {
  font-size: 10px;
  line-height: 12px;
  color: inherit;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.typo-caption {
  font-size: 12px;
  line-height: 16px;
  color: inherit;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.typo-accent-m {
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.typo-body-m {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.typo-monospace {
  font-family: Courier New, monospace;
}

ul, ol {
  color: $color-black;
  list-style: none;
  margin: 0 0 18px 0;
}

ul {
  font-size: $font-size-base;
  padding: 0 0 0 15px;

  li {
    margin: 5px 0;
    position: relative;

    &:before {
      position: absolute;
      left: -14px;
      top: 50%;
      margin-top: -4px;
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $color-black;
    }
  }
}

ol {
  font-size: $font-size-base;
  padding: 0 0 0 15px;
  list-style: decimal outside;

  li {
    margin: 5px 0;
  }
}
