$gap-h: 20px;
$gap-v: 32px;

.ticket-head {
  $base-class: &;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include for-size(phone-landscape-down) {
    flex-direction: column;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    @include for-size(phone-landscape-down) {
      margin-left: 0;
    }
  }

  &__label {
    @include for-size(phone-landscape-down) {
      display: none;
    }
  }

  &__filters {
    position: relative;
    height: 24px;
    line-height: 24px;
    z-index: 5;
    @include for-size(phone-landscape-down) {
      margin-right: 5px;
    }

    &:not(:last-child) {
      padding-right: 20px;
      border-right: 1px solid $color-black-op-25;
      margin-right: 20px;
    }

    &:hover {
      #{$base-class}__filters-list {
        visibility: visible;
        opacity: 1;
      }
      #{$base-class}__filters-btn {
        color: $primary-color-hover;
      }
    }
  }
  &__filters-btn {
    color: $color-black;
    transition: color $base-animation-time;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  &__filters-list {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    right: 0;
    min-width: 200px;
    background-color: $color-white;
    padding: 16px 20px;
    box-shadow: $base-shadow-l;
    border-radius: 8px;
    transition: visibility $base-animation-time, opacity $base-animation-time;
  }
  &__filters-item {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:hover,
    &--active {
      cursor: pointer;
      color: $primary-color-hover;
    }
  }

  &__filter {
    $base-class: &;
    position: relative;
    height: 24px;
    z-index: 5;
    margin-right: 20px;
    @include for-size(phone-landscape-down) {
      margin-right: 5px;
    }

    &:hover {
      #{$base-class}__filter-btn {
        color: $primary-color-hover;
      }
    }
  }
  &__filter-btn {
    color: $color-black;
    transition: color $base-animation-time;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &__view {
    display: flex;
    color: $color-black;
    transition: color $base-animation-time;

    @include for-size(phone-landscape-down) {
      padding-left: 12px;
    }
  }
  &__view-item {
    & + & {
      margin-left: 12px;
    }

    &:hover,
    &--active {
      color: $primary-color-hover;
      cursor: pointer;
    }
  }
}

.tickets-list {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-bottom: $gap-v;
  @supports (display: grid) {
    &--card {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      grid-gap: 20px;
      margin-left: unset;
    }
  }
}
.tickets-head {
  $base-class: &;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  &__body {
    width: 82%;
    padding: 0 20px 0 40px;
    flex-grow: 1;
    display: flex;
    #{$base-class}__item:nth-child(1) {
      width: 32%;
    }
    #{$base-class}__item:nth-child(2) {
      width: 24%;
    }
    #{$base-class}__item:nth-child(3) {
      width: 12%;
    }
    #{$base-class}__item:nth-child(4) {
      width: 14%;
    }
    #{$base-class}__item:nth-child(5) {
      width: 18%;
    }
  }
  &__actions {
    width: 18%;
    flex-grow: 1;
  }
  &__item {
    font-size: 14px;
    line-height: 20px;
    color: rgba(16, 24, 40, 0.5);
    flex-grow: 1;
    &--right {
      text-align: right;
      &:not(:last-child) {
        padding-right: 16px;
      }
    }
    &--center {
      text-align: center;
    }
  }
}

.tickets-more {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.tickets-actions {
  position: fixed;
  width: calc(100% - #{$sidebar-width});
  padding: 16px 0 16px 40px;
  background-color: $color-white;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  box-shadow: inset 1px 0 0 $base-shadow-color;
  z-index: 2;
  &--wide {
    width: calc(100% - #{$sidebar-collapse-width});
  }
  &__item + &__item {
    margin-left: 12px;
  }
}
