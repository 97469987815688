.accent-block {
  padding: $gap;
  background: $base-accent-linear-gradient;
  border-radius: 12px;
  margin-bottom: 32px;
  color: $color-white;

  @include for-size(phone-portrait-down) {
    margin: 0 -20px 24px;
    border-radius: 0 0 12px 12px;
    padding: 20px 20px 14px;
  }
}
