.ck {
  .ck.ck-balloon-panel {
    z-index: 2000;
  }
  
}
.wysiwyg {
  .ck.ck-content,
  .ck.ck-toolbar {
    background-color: $color-black-op-05;
  }
}