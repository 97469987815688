
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.old-browser-info {
  padding: calc(#{$gap} / 2);
  display: flex;
  justify-content: center;
  align-items: center;
}
