.page {
  min-height: 100%;

  &__title {
    margin-bottom: 20px;
  }

  &--bottom-indent {
    padding-bottom: 80px;
  }

  &__action-buttons {
    position: fixed;
    width: calc(100% - #{$sidebar-width});
    display: flex;
    padding: 16px 0 16px 40px;
    background-color: $color-white;
    bottom: 0;
    right: 0;
    transition: width $base-animation-time;
    box-shadow: inset 1px 0 0 $base-shadow-color;
    z-index: 2;
    &--wide {
      width: calc(100% - #{$sidebar-collapse-width});
    }
  }
  &__action-button {
    $item-class: &;
    & + & {
      margin-left: $base-gutter;
    }
    &#{$item-class}--right {
      margin-left: auto;
      margin-right: $base-gutter;
    }
  }
}

.layout {
  &--with-header {
    background-color: $color-grayscale-05;
  }
}
